import React from 'react'
import { Link } from 'react-router-dom'

export const ML = () => {
  return (
    <>
    <div className="flex flex-col md:flex-row justify-center space-y-2 md:space-y-0 md:space-x-4 p-4">
    <Link to="/iee" className="bg-transparent text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white py-2 px-4 rounded transition duration-300">
                Python Projects
            </Link>
            <Link to="/ml" className="bg-transparent text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white py-2 px-4 rounded transition duration-300">
                ML Projects
            </Link>
            <Link to="/android" className="bg-transparent text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white py-2 px-4 rounded transition duration-300">
                Android Projects
            </Link>
            <Link to="/java" className="bg-transparent text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white py-2 px-4 rounded transition duration-300">
                Java Projects
            </Link>
            <Link to="/vlsi" className="bg-transparent text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white py-2 px-4 rounded transition duration-300">
                VLSI Projects
            </Link>
        </div>


    <div className="max-w-6xl mx-auto p-4">
      <p className="text-2xl font-semibold text-center mb-4">IEEE Machine Learning Projects 2024 – 2025 Machine Learning Project Titles</p>
      <p className="text-xl text-center mb-8">IEEE Python Projects 2024 2025 Machine Learning Projects, Deep Learning Projects, Artificial Intelligence Titles, Data Science Project Ideas for Final Year 2024 2025</p>
      <div className="overflow-x-auto">
        

<div class="relative overflow-x-auto shadow-md sm:rounded-lg">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3 font-bold text-blue-500 dark:text-blue-700">
                   Sr.No
                </th>
                <th scope="col" class="px-6 py-3 font-bold text-blue-500 dark:text-blue-700">
                    Domain
                </th>
                <th scope="col" class="px-6 py-3 font-bold text-blue-500 dark:text-blue-700">
                    Project Tittle
                </th>
                
                <th scope="col" class="px-6 py-3 font-bold text-blue-500 dark:text-blue-700">
                    Download
                </th>
                <th scope="col" class="px-6 py-3 font-bold text-blue-500 dark:text-blue-700">
                    Download
                </th>
                <th scope="col" class="px-6 py-3 font-bold text-blue-500 dark:text-blue-700">
                    Price
                </th>
                <th scope="col" class="px-6 py-3 font-bold text-blue-500 dark:text-blue-700">
                    Download
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2401
                </th>
                <td class="px-6 py-4 font-bold">
                Blockchain 
                </td>
                <td class="px-6 py-4 font-bold">
                Speech Emotion Recognition
                </td>
                
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $2999
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2402
                </th>
                <td class="px-6 py-4 font-bold">
                Machine Learning
                </td>
                <td class="px-6 py-4 font-bold">
                Human Activity Recognition
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $1999
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Machine Learning
                </td>
                <td class="px-6 py-4 font-bold">
                Financial Market Prediction
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Machine Learning
                </td>
                <td class="px-6 py-4 font-bold">
                Augmented Reality with Machine Learning
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Machine Learning
                </td>
                <td class="px-6 py-4 font-bold">
                3D Object Detection
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Machine Learning
                </td>
                <td class="px-6 py-4 font-bold">
                Plant Disease Detection
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Machine Learning
                </td>
                <td class="px-6 py-4 font-bold">
                Fake News Detection
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Machine Learning
                </td>
                <td class="px-6 py-4 font-bold">
                AI-Powered Personal Finance Assistant
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Machine Learning
                </td>
                <td class="px-6 py-4 font-bold">
                Sports Analytics
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Machine Learning
                </td>
                <td class="px-6 py-4 font-bold">
                Anomaly Detection in Network Traffic
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Machine Learning
                </td>
                <td class="px-6 py-4 font-bold">
                Music Generation with RNNs                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Machine Learning
                </td>
                <td class="px-6 py-4 font-bold">
                Optical Character Recognition (OCR)
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Machine Learning
                </td>
                <td class="px-6 py-4 font-bold">
                Image Super-Resolution:
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Machine Learning
                </td>
                <td class="px-6 py-4 font-bold">
                Text Summarization
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Machine Learning
                </td>
                <td class="px-6 py-4 font-bold">
                Style Transfer in Images
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Machine Learning
                </td>
                <td class="px-6 py-4 font-bold">
                Customer Churn Prediction
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Machine Learning
                </td>
                <td class="px-6 py-4 font-bold">
                Emotion Detection from Speech
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Machine Learning
                </td>
                <td class="px-6 py-4 font-bold">
                Traffic Sign Recognition
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Machine Learning
                </td>
                <td class="px-6 py-4 font-bold">
                Automated Essay Grading
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Machine Learning
                </td>
                <td class="px-6 py-4 font-bold">
                Disease Prediction Model
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Machine Learning
                </td>
                <td class="px-6 py-4 font-bold">
                Voice Recognition System
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Machine Learning
                </td>
                <td class="px-6 py-4 font-bold">
                Time Series Forecasting
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Machine Learning
                </td>
                <td class="px-6 py-4 font-bold">
                Chatbot Development
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Machine Learning
                </td>
                <td class="px-6 py-4 font-bold">
                Recommendation System
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Machine Learning
                </td>
                <td class="px-6 py-4 font-bold">
                Image Captioning
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Machine Learning
                </td>
                <td class="px-6 py-4 font-bold">
                Fraud Detection System
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Machine Learning
                </td>
                <td class="px-6 py-4 font-bold">
                DSentiment Analysis on Social Media
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Machine Learning
                </td>
                <td class="px-6 py-4 font-bold">
                Generative Adversarial Networks (GANs)
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Machine Learning
                </td>
                <td class="px-6 py-4 font-bold">
                Self-Driving Car Simulation
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
        </tbody>
    </table>
    <div className="max-w-6xl mx-auto p-4">
      <p className="text-2xl font-medium text-gray-800 text-center mb-4">Importance Of Machine Learning</p>
      <p className="text-lg text-justify text-sm text-gray-700">
      Python is a versatile programming language that has gained immense popularity due to its simplicity and readability, making it an ideal choice for both beginners and experienced developers. Its extensive libraries and frameworks enable rapid development across various domains, including web development, data analysis, artificial intelligence, machine learning, and automation. Python’s strong community support fosters continuous growth and innovation, allowing developers to easily access resources and share knowledge. Additionally, its compatibility with other languages and platforms enhances its functionality, making Python a preferred tool for many organizations. As technology continues to evolve, Python's relevance remains strong, positioning it as a critical skill for future careers in tech.
      </p>
    </div>

    <div className="max-w-6xl mx-auto p-4">
    <p className="text-lg text-justify text-medium text-gray-800">
    IEEE papers on  Machine Learning final year projects IEEE Machine learning projects for final year with source code in Python IEEE projects for CSE 2023 2024
      </p>
      <p className="text-xl font-medium text-purple-800 text-start mb-4 mt-3">What is Machine Learning?</p>
      <p className="text-lg text-justify text-sm text-gray-700">
      Machine learning is a subset of artificial intelligence (AI) that focuses on developing algorithms and statistical models that enable computers to learn from and make predictions or decisions based on data. Rather than being explicitly programmed for specific tasks, machine learning systems improve their performance through exposure to more data over time. It encompasses three main types: supervised learning, where models are trained on labeled data to predict outputs; unsupervised learning, which identifies patterns in unlabeled data; and reinforcement learning, where models learn by interacting with an environment to maximize rewards. Machine learning is widely applied in various fields, including image and speech recognition, natural language processing, recommendation systems, and autonomous vehicles, significantly transforming industries and enhancing everyday technology.
      </p>
    </div>
</div>

      </div>
    </div>
    </>
  )
}
