import React from 'react'
import { Link } from 'react-router-dom'

export const VLSI = () => {
  return (
    <>
    <div className="flex flex-col md:flex-row justify-center space-y-2 md:space-y-0 md:space-x-4 p-4">
    <Link to="/iee" className="bg-transparent text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white py-2 px-4 rounded transition duration-300">
                Python Projects
            </Link>
            <Link to="/ml" className="bg-transparent text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white py-2 px-4 rounded transition duration-300">
                ML Projects
            </Link>
            <Link to="/android" className="bg-transparent text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white py-2 px-4 rounded transition duration-300">
                Android Projects
            </Link>
            <Link to="/java" className="bg-transparent text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white py-2 px-4 rounded transition duration-300">
                Java Projects
            </Link>
            <Link to="/vlsi" className="bg-transparent text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white py-2 px-4 rounded transition duration-300">
                VLSI Projects
            </Link>
        </div>


    <div className="max-w-6xl mx-auto p-4">
      <p className="text-2xl font-semibold text-center mb-4">IEEE VLSI Projects 2024 – 2025 VLSI Project Titles</p>
      <p className="text-xl text-center mb-8">IEEE VLSI Projects 2024 2025 Machine Learning Projects, Deep Learning Projects, Artificial Intelligence Titles, Data Science Project Ideas for Final Year 2024 2025</p>
      <div className="overflow-x-auto">
        

<div class="relative overflow-x-auto shadow-md sm:rounded-lg">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3 font-bold text-blue-500 dark:text-blue-700">
                   Sr.No
                </th>
                <th scope="col" class="px-6 py-3 font-bold text-blue-500 dark:text-blue-700">
                    Domain
                </th>
                <th scope="col" class="px-6 py-3 font-bold text-blue-500 dark:text-blue-700">
                    Project Tittle
                </th>
                
                <th scope="col" class="px-6 py-3 font-bold text-blue-500 dark:text-blue-700">
                    Download
                </th>
                <th scope="col" class="px-6 py-3 font-bold text-blue-500 dark:text-blue-700">
                    Download
                </th>
                <th scope="col" class="px-6 py-3 font-bold text-blue-500 dark:text-blue-700">
                    Price
                </th>
                <th scope="col" class="px-6 py-3 font-bold text-blue-500 dark:text-blue-700">
                    Download
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2401
                </th>
                <td class="px-6 py-4 font-bold">
               VLSI
                </td>
                <td class="px-6 py-4 font-bold">
                VLSI Testing and Debugging Techniques
                </td>
                
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $2999
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2402
                </th>
                <td class="px-6 py-4 font-bold">
                VLSI
                </td>
                <td class="px-6 py-4 font-bold">
                Clock Distribution Network Design
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $1999
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                VLSI
                </td>
                <td class="px-6 py-4 font-bold">
                VLSI for Biomedical Applications
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                VLSI
                </td>
                <td class="px-6 py-4 font-bold">
                Fault-Tolerant VLSI Design
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                VLSI
                </td>
                <td class="px-6 py-4 font-bold">
                Neural Network Hardware Implementation
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                VLSI
                </td>
                <td class="px-6 py-4 font-bold">
                Wireless Sensor Network on Chip
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                VLSI
                </td>
                <td class="px-6 py-4 font-bold">
                Reconfigurable Computing Architecture
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                VLSI
                </td>
                <td class="px-6 py-4 font-bold">
                VLSI-Based Image Processing System
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                VLSI
                </td>
                <td class="px-6 py-4 font-bold">
                Digital Filter Implementation
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                VLSI
                </td>
                <td class="px-6 py-4 font-bold">
                Analog-to-Digital Converter (ADC) Design                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                VLSI
                </td>
                <td class="px-6 py-4 font-bold">
                DeepFake Face Detection using Machine Learning
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                VLSI
                </td>
                <td class="px-6 py-4 font-bold">
                Low-Voltage VLSI Circuit Design
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                VLSI
                </td>
                <td class="px-6 py-4 font-bold">
                3D Integrated Circuits Design
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                VLSI
                </td>
                <td class="px-6 py-4 font-bold">
                High-Speed Serializer/Deserializer (SerDes)</td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                VLSI
                </td>
                <td class="px-6 py-4 font-bold">
                DMemory Architecture Design
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                VLSI
                </td>
                <td class="px-6 py-4 font-bold">
                Digital Communication System on Chip
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                VLSI
                </td>
                <td class="px-6 py-4 font-bold">
                Self-Powered VLSI Circuits
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                VLSI
                </td>
                <td class="px-6 py-4 font-bold">
                Hardware Security Implementation
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                VLSI
                </td>
                <td class="px-6 py-4 font-bold">
                Custom Instruction Set Architecture (ISA)
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                VLSI
                </td>
                <td class="px-6 py-4 font-bold">
                VLSI-Based Machine Learning Accelerator
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                VLSI
                </td>
                <td class="px-6 py-4 font-bold">
                Edge Computing Architecture
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                VLSI
                </td>
                <td class="px-6 py-4 font-bold">
                Phase-Locked Loop (PLL) Design
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                VLSI
                </td>
                <td class="px-6 py-4 font-bold">
                Quantum Dot Cellular Automata (QCA) Design
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                VLSI
                </td>
                <td class="px-6 py-4 font-bold">
                Power Grid Design for VLSI
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                VLSI
                </td>
                <td class="px-6 py-4 font-bold">
                High-Performance VLSI Circuits for AI Applications
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                VLSI
                </td>
                <td class="px-6 py-4 font-bold">
                System-on-Chip (SoC) Design
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                VLSI
                </td>
                <td class="px-6 py-4 font-bold">
                VLSI Design Automation Tools
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                VLSI
                </td>
                <td class="px-6 py-4 font-bold">
                FPGA-Based Signal Processing
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                VLSI
                </td>
                <td class="px-6 py-4 font-bold">
                Low-Power VLSI Design
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
        </tbody>
    </table>
    <div className="max-w-6xl mx-auto p-4">
      <p className="text-2xl font-medium text-gray-800 text-center mb-4">Importance Of VLSI</p>
      <p className="text-lg text-justify text-sm text-gray-700">
      VLSI, or Very Large Scale Integration, is crucial in modern electronics due to its ability to pack immense functionality into compact semiconductor chips, enabling the miniaturization of devices while enhancing performance and efficiency. This technology underpins a wide array of applications, from smartphones and computers to automotive systems and medical devices, driving innovation across industries. VLSI allows for the integration of complex circuits that can perform multiple functions, significantly reducing costs and power consumption compared to traditional discrete component designs. Furthermore, as the demand for higher performance and smarter technologies grows, VLSI continues to evolve, supporting advancements in artificial intelligence, machine learning, and the Internet of Things (IoT). Its role in enhancing computational power, improving energy efficiency, and enabling new functionalities makes VLSI a cornerstone of modern technological development, impacting everyday life and driving economic growth.
      </p>
    </div>

    <div className="max-w-6xl mx-auto p-4">
    <p className="text-lg text-justify text-medium text-gray-800">
    IEEE papers on VLSI final year projects IEEE VLSI projects for final year with source code in VLSI IEEE projects for CSE 2023 2024
      </p>
      <p className="text-xl font-medium text-purple-800 text-start mb-4 mt-3">What is VLSI?</p>
      <p className="text-lg text-justify text-sm text-gray-700">
      VLSI, or Very Large Scale Integration, is a technology that enables the integration of thousands, millions, or even billions of electronic components, such as transistors, onto a single semiconductor chip. This advancement has revolutionized the field of electronics, allowing for the miniaturization of circuits and the creation of complex systems in a compact form factor. VLSI technology plays a crucial role in the design and manufacturing of integrated circuits (ICs) used in various applications, including computers, smartphones, consumer electronics, and automotive systems. The development of VLSI has led to significant improvements in performance, power efficiency, and functionality, facilitating the creation of highly sophisticated devices that support advanced features and applications. With ongoing innovations in fabrication techniques and design methodologies, VLSI continues to drive progress in electronics, enabling the development of next-generation technologies such as artificial intelligence, IoT, and high-performance computing.
      </p>
    </div>
</div>

      </div>
    </div>
    
    
    </>
  )
}
