import React from 'react'
import { Link } from 'react-router-dom'

export const Java = () => {
  return (
    <>
    <div className="flex flex-col md:flex-row justify-center space-y-2 md:space-y-0 md:space-x-4 p-4">
    <Link to="/iee" className="bg-transparent text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white py-2 px-4 rounded transition duration-300">
                Python Projects
            </Link>
            <Link to="/ml" className="bg-transparent text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white py-2 px-4 rounded transition duration-300">
                ML Projects
            </Link>
            <Link to="/android" className="bg-transparent text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white py-2 px-4 rounded transition duration-300">
                Android Projects
            </Link>
            <Link to="/java" className="bg-transparent text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white py-2 px-4 rounded transition duration-300">
                Java Projects
            </Link>
            <Link to="/vlsi" className="bg-transparent text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white py-2 px-4 rounded transition duration-300">
                VLSI Projects
            </Link>
        </div>


    <div className="max-w-6xl mx-auto p-4">
      <p className="text-2xl font-semibold text-center mb-4">IEEE Java Projects 2024 – 2025 Java Project Titles</p>
      <p className="text-xl text-center mb-8">IEEE Java Projects 2024 2025 Machine Learning Projects, Deep Learning Projects, Artificial Intelligence Titles, Data Science Project Ideas for Final Year 2024 2025</p>
      <div className="overflow-x-auto">
        

<div class="relative overflow-x-auto shadow-md sm:rounded-lg">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3 font-bold text-blue-500 dark:text-blue-700">
                   Sr.No
                </th>
                <th scope="col" class="px-6 py-3 font-bold text-blue-500 dark:text-blue-700">
                    Domain
                </th>
                <th scope="col" class="px-6 py-3 font-bold text-blue-500 dark:text-blue-700">
                    Project Tittle
                </th>
                
                <th scope="col" class="px-6 py-3 font-bold text-blue-500 dark:text-blue-700">
                    Download
                </th>
                <th scope="col" class="px-6 py-3 font-bold text-blue-500 dark:text-blue-700">
                    Download
                </th>
                <th scope="col" class="px-6 py-3 font-bold text-blue-500 dark:text-blue-700">
                    Price
                </th>
                <th scope="col" class="px-6 py-3 font-bold text-blue-500 dark:text-blue-700">
                    Download
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2401
                </th>
                <td class="px-6 py-4 font-bold">
                Java
                </td>
                <td class="px-6 py-4 font-bold">
                Travel Expense Tracker
                </td>
                
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $2999
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2402
                </th>
                <td class="px-6 py-4 font-bold">
                Java
                </td>
                <td class="px-6 py-4 font-bold">
                News Aggregator
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $1999
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Java
                </td>
                <td class="px-6 py-4 font-bold">
                Task Management System
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Java
                </td>
                <td class="px-6 py-4 font-bold">
                Virtual Classroom
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Java
                </td>
                <td class="px-6 py-4 font-bold">
                Online Code Editor
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Java
                </td>
                <td class="px-6 py-4 font-bold">
                Customer Relationship Management (CRM) System
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Java
                </td>
                <td class="px-6 py-4 font-bold">
                Augmented Reality Application
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Java
                </td>
                <td class="px-6 py-4 font-bold">
                Distributed File Storage System
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Java
                </td>
                <td class="px-6 py-4 font-bold">
                Recommendation System
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Java
                </td>
                <td class="px-6 py-4 font-bold">
                Sentiment Analysis Tool
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Java
                </td>
                <td class="px-6 py-4 font-bold">
                Event Management System
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Java
                </td>
                <td class="px-6 py-4 font-bold">
                Travel Planner Application
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Java
                </td>
                <td class="px-6 py-4 font-bold">
                Health Monitoring System:
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Java
                </td>
                <td class="px-6 py-4 font-bold">
                Game Development:
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Java
                </td>
                <td class="px-6 py-4 font-bold">
                Automated Testing Framework: 
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Java
                </td>
                <td class="px-6 py-4 font-bold">
                Search Engine:
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Java
                </td>
                <td class="px-6 py-4 font-bold">
                Custom Web Scraper
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Java
                </td>
                <td class="px-6 py-4 font-bold">
                Data Visualization Tool
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Java
                </td>
                <td class="px-6 py-4 font-bold">
                Online Voting System:
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Java
                </td>
                <td class="px-6 py-4 font-bold">
                Blockchain-Based Application:
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Java
                </td>
                <td class="px-6 py-4 font-bold">
                Content Management System (CMS)
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Java
                </td>
                <td class="px-6 py-4 font-bold">
                API Development for a Microservices Architecture
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Java
                </td>
                <td class="px-6 py-4 font-bold">
                IoT Dashboard
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Java
                </td>
                <td class="px-6 py-4 font-bold">
                Financial Management System:
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Java
                </td>
                <td class="px-6 py-4 font-bold">
                Booking System
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Java
                </td>
                <td class="px-6 py-4 font-bold">
                Real-Time Chat Application:
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Java
                </td>
                <td class="px-6 py-4 font-bold">
                Online Learning Management System </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Java
                </td>
                <td class="px-6 py-4 font-bold">
                Social Media Application
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Java
                </td>
                <td class="px-6 py-4 font-bold">
                E-Commerce Platform
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
        </tbody>
    </table>
    <div className="max-w-6xl mx-auto p-4">
      <p className="text-2xl font-medium text-gray-800 text-center mb-4">Importance Of Java</p>
      <p className="text-lg text-justify text-sm text-gray-700">
      Java is a highly significant programming language that has maintained its relevance for decades due to its versatility, platform independence, and robust performance. As an object-oriented language, Java promotes code reusability and modular design, making it easier for developers to manage complex applications. Its "Write Once, Run Anywhere" (WORA) capability, facilitated by the Java Virtual Machine (JVM), allows Java applications to run on any device that supports Java, fostering cross-platform compatibility. This has made Java the preferred choice for enterprise applications, Android app development, and large-scale systems. Furthermore, Java has a rich ecosystem of libraries and frameworks, such as Spring and Hibernate, which accelerate development and enhance functionality. Its strong community support ensures continuous improvement and provides ample resources for learning and troubleshooting. Additionally, Java's emphasis on security, scalability, and performance makes it ideal for developing secure web applications and high-performance systems, solidifying its position as a cornerstone of modern software development.
      </p>
    </div>

    <div className="max-w-6xl mx-auto p-4">
    <p className="text-lg text-justify text-medium text-gray-800">
    IEEE papers on Java final year projects IEEE Java projects for final year with source code in Web IEEE projects for CSE 2023 2024
      </p>
      <p className="text-xl font-medium text-purple-800 text-start mb-4 mt-3">What is Java?</p>
      <p className="text-lg text-justify text-sm text-gray-700">
      Java is a versatile, high-level programming language developed by Sun Microsystems in the mid-1990s, now owned by Oracle Corporation. It is designed to be platform-independent, allowing developers to write code that can run on any device equipped with the Java Virtual Machine (JVM), which interprets the bytecode produced by Java compilers. Java is an object-oriented language, which means it emphasizes concepts such as encapsulation, inheritance, and polymorphism, promoting reusable and maintainable code. Its syntax is similar to C and C++, making it accessible to developers familiar with those languages. Java is widely used in various domains, including enterprise applications, web development, mobile applications (especially Android), and large-scale systems. With a rich ecosystem of libraries and frameworks, Java supports a vast array of functionalities, making it a popular choice for developers and businesses alike. Its emphasis on security, performance, and community support further solidifies Java's enduring presence in the programming landscape.
      </p>
    </div>
</div>

      </div>
    </div>
    </>
  )
}
