import React from 'react'
import { Link } from 'react-router-dom'

export const Android = () => {
  return (
    <>
    <div className="flex flex-col md:flex-row justify-center space-y-2 md:space-y-0 md:space-x-4 p-4">
    <Link to="/iee" className="bg-transparent text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white py-2 px-4 rounded transition duration-300">
                Python Projects
            </Link>
            <Link to="/ml" className="bg-transparent text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white py-2 px-4 rounded transition duration-300">
                ML Projects
            </Link>
            <Link to="/android" className="bg-transparent text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white py-2 px-4 rounded transition duration-300">
                Android Projects
            </Link>
            <Link to="/java" className="bg-transparent text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white py-2 px-4 rounded transition duration-300">
                Java Projects
            </Link>
            <Link to="/vlsi" className="bg-transparent text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white py-2 px-4 rounded transition duration-300">
                VLSI Projects
            </Link>
        </div>
      


    <div className="max-w-6xl mx-auto p-4">
      <p className="text-2xl font-semibold text-center mb-4">IEEE Android App Development Projects 2024 – 2025 Python Project Titles</p>
      <p className="text-xl text-center mb-8">IEEE Android App Development Projects 2024 2025 Machine Learning Projects, Deep Learning Projects, Artificial Intelligence Titles, Data Science Project Ideas for Final Year 2024 2025</p>
      <div className="overflow-x-auto">
        

<div class="relative overflow-x-auto shadow-md sm:rounded-lg">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3 font-bold text-blue-500 dark:text-blue-700">
                   Sr.No
                </th>
                <th scope="col" class="px-6 py-3 font-bold text-blue-500 dark:text-blue-700">
                    Domain
                </th>
                <th scope="col" class="px-6 py-3 font-bold text-blue-500 dark:text-blue-700">
                    Project Tittle
                </th>
                
                <th scope="col" class="px-6 py-3 font-bold text-blue-500 dark:text-blue-700">
                    Download
                </th>
                <th scope="col" class="px-6 py-3 font-bold text-blue-500 dark:text-blue-700">
                    Download
                </th>
                <th scope="col" class="px-6 py-3 font-bold text-blue-500 dark:text-blue-700">
                    Price
                </th>
                <th scope="col" class="px-6 py-3 font-bold text-blue-500 dark:text-blue-700">
                    Download
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2401
                </th>
                <td class="px-6 py-4 font-bold">
                Android Projects 
                </td>
                <td class="px-6 py-4 font-bold">
                AI-Powered Personal Assistant
                </td>
                
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $2999
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2402
                </th>
                <td class="px-6 py-4 font-bold">
                Android Projects
                </td>
                <td class="px-6 py-4 font-bold">
                Augmented Reality Shopping App
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $1999
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Android Projects
                </td>
                <td class="px-6 py-4 font-bold">
                Fitness Tracker with Machine Learning
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Android Projects
                </td>
                <td class="px-6 py-4 font-bold">
                Social Networking App for Local Communities
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Android Projects
                </td>
                <td class="px-6 py-4 font-bold">
                E-Learning Platform
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Android Projects
                </td>
                <td class="px-6 py-4 font-bold">
                IoT Home Automation App
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Android Projects
                </td>
                <td class="px-6 py-4 font-bold">
                Mental Health Support App
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Android Projects
                </td>
                <td class="px-6 py-4 font-bold">
                Recipe Finder with Ingredient Scanner
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Android Projects
                </td>
                <td class="px-6 py-4 font-bold">
                Travel Planner with Real-Time Updates
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Android Projects
                </td>
                <td class="px-6 py-4 font-bold">
                Personal Finance Management Tool
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Android Projects
                </td>
                <td class="px-6 py-4 font-bold">
                Collaborative Task Management App
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Android Projects
                </td>
                <td class="px-6 py-4 font-bold">
                Voice-Activated Navigation App
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Android Projects
                </td>
                <td class="px-6 py-4 font-bold">
                E-Commerce App with AR Features
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Android Projects
                </td>
                <td class="px-6 py-4 font-bold">
                Event Discovery and Management App
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Android Projects
                </td>
                <td class="px-6 py-4 font-bold">
                Health Monitoring App for Wearables
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Android Projects
                </td>
                <td class="px-6 py-4 font-bold">
                Real Estate App with 3D Visualization
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Android Projects
                </td>
                <td class="px-6 py-4 font-bold">
                Language Learning App with Gamification
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Android Projects
                </td>
                <td class="px-6 py-4 font-bold">
                Customizable News Aggregator
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Android Projects
                </td>
                <td class="px-6 py-4 font-bold">
                Photo Editing App with AI Filters
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Android Projects
                </td>
                <td class="px-6 py-4 font-bold">
                Smart Shopping List App
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Android Projects
                </td>
                <td class="px-6 py-4 font-bold">
                Disaster Alert and Response App
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Android Projects
                </td>
                <td class="px-6 py-4 font-bold">
                Pet Care Management App
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
      Android Projects
                </td>
                <td class="px-6 py-4 font-bold">
                Crowdsourced Delivery App
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Android Projects
                </td>
                <td class="px-6 py-4 font-bold">
                Digital Portfolio for Creatives
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Android Projects
                </td>
                <td class="px-6 py-4 font-bold">
                Remote Learning and Tutoring App
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Android Projects
                </td>
                <td class="px-6 py-4 font-bold">
                Public Transportation Tracker
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Android Projects
                </td>
                <td class="px-6 py-4 font-bold">
                Personalized News Feed Using Machine Learning
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Android Projects
                </td>
                <td class="px-6 py-4 font-bold">
                Smart Habit Tracker
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                IXP2403
                </th>
                <td class="px-6 py-4 font-bold">
                Android Projects
                </td>
                <td class="px-6 py-4 font-bold">
                Emergency Response App
                </td>
               
                <td class="px-6 py-4">
                    <a href="#" class="font-bold  text-green-600 dark:text-green-500 hover:underline">ABSTRACT</a>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-red-600 dark:text-red-500 hover:underline">BASEPAPER</a>
                </td>
                <td class="px-6 py-4 font-bold text-blue-500 dark:text-blue-700 hover:underline">
                    $99
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-bold text-purple-800 dark:text-purple-500 hover:underline">VIDEO</a>
                </td>
            </tr>
        </tbody>
    </table>
    <div className="max-w-6xl mx-auto p-4">
      <p className="text-2xl font-medium text-gray-800 text-center mb-4">Importance Of Android App Development</p>
      <p className="text-lg text-justify text-sm text-gray-700">
      Android app development is crucial for several reasons, reflecting its impact on businesses, users, and the tech landscape. A large developer community supports Android development, offering resources, forums, and open-source projects that foster collaboration and knowledge sharing.Android apps can collect and analyze user data, providing valuable insights that help businesses refine their strategies and improve user experience.
      </p>
    </div>

    <div className="max-w-6xl mx-auto p-4">
    <p className="text-lg text-justify text-medium text-gray-800">
    IEEE papers on Android App Development final year projects IEEE Android App Development projects for final year with source code in Android app IEEE projects for CSE 2023 2024
      </p>
      <p className="text-xl font-medium text-purple-800 text-start mb-4 mt-3">What is Android Development ?</p>
      <p className="text-lg text-justify text-sm text-gray-700">
      Android development is the process of creating applications for devices running the Android operating system, which is an open-source platform primarily used for smartphones and tablets. It involves using tools and environments like Android Studio, the official Integrated Development Environment (IDE), along with the Software Development Kit (SDK) that provides essential libraries and APIs. Developers primarily use programming languages such as Java and Kotlin, with XML employed for user interface design. Key components of an Android app include activities, services, broadcast receivers, and content providers, each serving a distinct function in the app's architecture. UI design often follows Google's Material Design guidelines to ensure a visually appealing and user-friendly experience. Testing and debugging are critical to ensure app functionality and performance across various devices, utilizing tools like the Android Emulator and JUnit. Once developed, apps are deployed primarily through the Google Play Store, and ongoing maintenance is essential to address user feedback and keep up with platform updates. As technology evolves, Android development embraces innovations like Jetpack Compose for UI design, machine learning, and IoT integration, ensuring that apps remain relevant and engaging.
      </p>
    </div>
</div>

      </div>
    </div>
    </>
  )
}
